/* @import url('https://fonts.googleapis.com/css?family=Stylish&display=swap'); */

body,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

html {
  background-color: #55b9f3;
}

a {
  text-decoration: none;
}

:root {
  --app-background: #55b9f3;

  --lightgrey-border: 2px solid lightgrey;
  --darkgrey-border: 2px solid darkgrey;
}

#not-rendered {
  color: var(--app-background);
}

/*chat box elements*/

a {
  border-top: var(--lightgrey-border);
  border-left: var(--lightgrey-border);
  border-bottom: var(--darkgrey-border);
  border-right: var(--darkgrey-border);
}

a:active {
  border-top: var(--darkgrey-border);
  border-left: var(--darkgrey-border);
  border-bottom: var(--lightgrey-border);
  border-right: var(--lightgrey-border);
}

.entry-group {
  --width: 500px;
  --offset: calc(50% - calc(var(--width) / 2));
  position: fixed;
  width: var(--width);
  left: var(--offset);
  right: var(--offset);
}

#chat-entry-group {
  bottom: 0;
}

#room-entry-group {
  --width: 60vw;
  top: 25vh;
  font-family: sans-serif;
  display: grid;
  grid-template-columns: 4fr 1fr;
}

input {
  border-top: var(--darkgrey-border);
  border-left: var(--darkgrey-border);
  border-bottom: var(--lightgrey-border);
  border-right: var(--lightgrey-border);

  width: 100%;
  margin-bottom: 20px;
  border-radius: 25px;
  outline: none;

  color: cornflowerblue;
  font-size: 20px;
  padding-left: 25px;
}

input::placeholder {
  color: cornflowerblue;
}

#room-input {
  height: 50px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 25px;
}

#chat-input {
  --height: 75px;
  height: var(--height);
  width: 100%;
  margin-bottom: 20px;
  border-radius: 25px;
  outline: none;

  color: cornflowerblue;
  font-size: 20px;
  padding-left: 25px;
}

#room-button {
  overflow: hidden;
  /* line-height: 50px; */
  /* vertical-align: top; */
  line-height: 50px;
  width: 100%;
  height: 50px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  outline: none;
  font-size: 35px;
  color: cornflowerblue; /*  rgb(118, 199, 247); */
  background-color: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* message elements */

#messages {
  width: 100vw;
  /*this has to be the issue*/
  /* position: fixed; */
  min-height: 100vh;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 95px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.message {
  text-align: center;
  margin: 44px auto;
  width: 50vw;
  min-height: 100px;
  /* border: 1px solid pink; */
  font: 60px/1.6 normal normal;
  font-family: Arial, Helvetica, sans-serif;
  /* font-family: "Baloo Chettan 2", Arial, Helvetica, sans-serif; */
  /*this is the chisel text effect*/
  /* color: #eee;
  text-shadow: 2px 2px #fff, -2px -2px #ccc; */
  color: rgb(78, 178, 236);
  text-shadow: 2px 2px rgb(95, 195, 253), -2px -2px rgb(42, 143, 202);
  /*this is from neumorphism.io*/
  border-radius: 12px;
  background: #55b9f3;
  box-shadow: 20px 20px 60px #489dcf, -20px -20px 60px #62d5ff;

  /* https://www.freecodecamp.org/forum/t/newline-in-react-string-solved/68484/12 */
  white-space: pre-wrap;
}

/* 833 px is where 500px === 60vw so the transition is seamless */
@media only screen and (max-width: 833px) {
  .entry-group {
    --width: 60vw;
  }

  .message {
    margin: 44px auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 85px;
    font-size: 45px;
    line-height: 1.2;
  }

  #room-button {
    font-size: 25px;
  }
}

/* .message-anim-enter {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  max-height: 0;
  padding: 0 !important;
  -webkit-transform: translateX(-120%);
  transform: translateX(-120%);
}
.message-anim-enter.message-anim-enter-active {
  max-height: 60px;
  padding: 2rem 0 !important;
  -webkit-transform: translateX(0);
  transform: translateX(0);
} */

/*its catching but it doesn't do what i want*/
/*also see where it puts the classname*/
.message-anim-enter {
  color: #55b9f3;
  text-shadow: none;
  /*
  border-radius: 12px;
  background: #55b9f3;
  */
  box-shadow: 20px 20px 60px rgba(72, 157, 207, 0),
    -20px -20px 60px rgba(97, 213, 255, 0);
}
.message-anim-enter.message-anim-enter-active {
  --timer: steps(10, end);
  --text-delay: 0.15s;
  color: rgb(78, 178, 236);
  /* -webkit-transition: box-shadow 0.75s steps(10, end) 0s,
    color 0.75s steps(10, end) 0s, text-shadow 0.75s steps(10, end) 0s; */
  transition: box-shadow 0.75s var(--timer) 0s,
    color 0.75s var(--timer) var(--text-delay),
    text-shadow 0.75s var(--timer) var(--text-delay);
  transition-timing-function: steps(10, end);
  text-shadow: 2px 2px rgb(95, 195, 253), -2px -2px rgb(42, 143, 202);
  /*
  border-radius: 12px;
  background: #55b9f3;
  */
  box-shadow: 20px 20px 60px #489dcf, -20px -20px 60px #62d5ff;
}

/* scrollbar from webkit */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.18);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red; /*rgba(255, 255, 255, 0.68);*/
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.85);
}
